<template>
	<ObservationArrangements></ObservationArrangements>
</template>

<script>
import ObservationArrangements from "@/components/shared/tools/observation/Arrangements.vue";

export default {
	name: "ObservationPage",

	components: {
		ObservationArrangements,
	},
};
</script>
